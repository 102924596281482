import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @dusan
 */

class AlertTypesSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        focus: PropTypes.bool,
    };

    render() {
        const { [GLOBAL_DATA.ALERT_TYPES]: types, focus, ...props } = this.props;
        return <Select
            {...props}
            focus={focus}
            options={types.map(ch => {
                return {
                    label: ch.name,
                    value: ch.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.ALERT_TYPES])(AlertTypesSelect);