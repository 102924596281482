import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../locationProvider/locationHOC';
import Table from '../general/Table';
import Tile from '../general/Tile';
import TileAttribute from '../general/TileAttribute';
import DateTime from '../general/DateTime';
import {navigateToParametrized, getQueryParam} from '../../lib/url';
import {numberOrNull} from '../../lib/number';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Alert, Alerts} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import CustomerLink from '../general/CustomerLink';
import AlertAck from './AlertAck';
import { ALERT_TYPE } from '../../constants/alertTypes';
import { objectHistoryClassLabel } from '../../lib/localizedHistoryLabels';
import ObjectLink from '../project/ObjectLink';

/**
 * @dusan
 */

class AlertsTable extends React.PureComponent {
    static propTypes = {
        alerts: Alerts.isRequired,
        reload: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ALERT_ORDER_BY]: newOrder,
            [QUERY_PARAMS.ALERT_SCROLL]: 0,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ALERT_LIMIT]: newLimit,
        });
    };

    render() {
        const {location, reload, alerts} = this.props;
        const orderBy = getQueryParam(location, QUERY_PARAMS.ALERT_ORDER_BY);
        const currentLimit = numberOrNull(getQueryParam(location, QUERY_PARAMS.ALERT_LIMIT));
        return <Table
            className="alerts-table"
            BodyRow={AlertsTableRow}
            BodyTile={AlertsTile}
            data={alerts}
            limit={currentLimit}
            pageSize={!Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20}
            changeLimit={this.setLimit}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            reload={reload}
            noTBody={false}
            colDefs={[
                {
                    headerCell: <Trans>Typ</Trans>,
                    orderCol: 'type',
                    class: 'alerts-table-col-type',
                },
                {
                    headerCell: <Trans>Dátum a čas</Trans>,
                    orderCol: 'tim',
                    class: 'alerts-table-col-date',
                },
                {
                    headerCell: <Trans>Detaily</Trans>,
                    orderCol: 'message',
                    class: 'alerts-table-col-message',
                },
                {
                    headerCell: <Trans>Trieda</Trans>,
                    orderCol: 'class',
                    class: 'alerts-table-col-class',
                },
                {
                    headerCell: <Trans>Organizácia</Trans>,
                    orderCol: 'customer',
                    class: 'alerts-table-col-customer',
                },
                {
                    headerCell: <Trans>Zopovedný obchodník a logista</Trans>,
                    orderCol: 'salesman',
                    class: 'alerts-table-col-salesman'
                },
                {
                    class: 'alerts-table-col-actions',
                },
            ]}
        />;
    }

}


class AlertsTableRow extends React.PureComponent {
    static propTypes = {
        data: Alert.isRequired,
        reload: PropTypes.func.isRequired,
    };

    getRowClass = () => {
        const {data} = this.props;
        if(data.is_active == 0)
            return 'white-background';

        switch(data.id_type)
        {
            case ALERT_TYPE.INFO:
                return 'light-blue-background';
            case ALERT_TYPE.REQUEST:
                return 'light-green-background';
            case ALERT_TYPE.WARNING:
                return 'light-yellow-background';
            case ALERT_TYPE.ALARM:
                return 'light-red-background';
            default:
                return null;
        }
    }

    render() {
        const {data, reload} = this.props;
        return <tr className={this.getRowClass()}>
            <td>{data.type}</td>
            <td>
                <DateTime timeString={data.tim}/>
                {data.is_active == 0 && data.closed_at != null ?
                    <div className="font-italic">
                        {t`Vybavil` + ' ' + (data.worker != null ? data.worker : t`Automat`)}
                    </div> : null
                }
            </td>
            <td>
                <ObjectLink
                    objClass={data.class}
                    objId={data.id_obj}
                    objLabel={data.msg}
                    customerId={data.id_customer}
                />
            </td>
            <td>{objectHistoryClassLabel(data.class, false)}</td>
            <td>
                {data.id_customer != null ?
                    <CustomerLink
                        customerId={data.id_customer}
                        customerName={data.customer}
                    /> : null
                }
            </td>
            <td>
               <div>{data.salesman}</div>
               <div className="font-italic">{data.salesman2}</div>
            </td>
            <td className="text-center">
                <AlertAck
                    alert={data}
                    reload={reload}
                />
            </td>
        </tr>;
    }
}


class AlertsTile extends React.PureComponent {
    static propTypes = {
        data: Alert.isRequired,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {data, reload} = this.props;
        return <Tile className="p-2">
                <TileAttribute
                    title={<Trans>Typ</Trans>}
                    value={data.type}
                />
                <TileAttribute
                    title={<Trans>Dátum a čas</Trans>}
                    value={<DateTime timeString={data.tim}/>}
                />
                <TileAttribute
                    title={<Trans>Trieda</Trans>}
                    value={objectHistoryClassLabel(data.class)}
                />
                <TileAttribute
                    title={<Trans>Organizácia</Trans>}
                    value={ data.id_customer != null ?
                        <CustomerLink
                            customerId={data.id_customer}
                            customerName={data.customer}
                        /> : null
                    }
                />
                <TileAttribute
                    title={<Trans>Zodpovedný obchodník</Trans>}
                    value={data.salesman}
                />
                <TileAttribute
                    title={<Trans>Logista</Trans>}
                    value={data.salesman2}
                />
                <TileAttribute
                    title={<Trans>Popis</Trans>}
                    value={data.msg}
                />
                <AlertAck
                    alert={data}
                    reload={reload}
                />
        </Tile>;
    }
}

export default locationHOC(AlertsTable);
