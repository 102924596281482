import {CLASSES, METHODS, ARGS} from '../constants/endpoints'
import {t} from '@lingui/macro';

/**
 * @fero
 */

export function objectHistoryClassLabel(value, showOrig=true) {
    if(objectHistoryClassLabel.dict == null)
    {
        objectHistoryClassLabel.dict = {
            [CLASSES.ORDER]: t`Objednávka`,
            [CLASSES.REQUEST]: t`Dopyt`,
            [CLASSES.QUOTATION]: t`Cenová ponuka`,
            [CLASSES.CART]: t`Košík`,
            [CLASSES.DISCOUNT]: t`Rabatová skupina`,
            [CLASSES.PRODUCT]: t`Produkt`,
            [CLASSES.TRANSFER]: t`Skl. doklad`,
            [CLASSES.INVENTORY]: t`Položka skladu`,
            [CLASSES.SHIPMENT]: t`Zásielka`,
            [CLASSES.INVOICE]: t`Faktúra`,
            [CLASSES.USER]: t`Kontakt`,
            [CLASSES.CUSTOMER]: t`Organizácia`,
            [CLASSES.COMPLAINT]: t`Reklamácia`,
            [CLASSES.NEWS]: t`Novinka`,
            [CLASSES.NOTES]: t`Poznámky`,
            [CLASSES.EVENT]: t`Event`,
            [CLASSES.BACKUP]: t`Zálohovanie`,
            [CLASSES.ASSIGNMENT]: t`Úloha`,
        };
    }

    const localized = objectHistoryClassLabel.dict[value];
    if(showOrig)
        return localized ? `${localized} (${value})` :  value;
    else
        return localized ? localized :  value;
}

export function objectHistoryMethodLabel(value, showOrig=true) {
    if (objectHistoryMethodLabel.dict == null)
    {
        objectHistoryMethodLabel.dict = {
            [METHODS.ADD]: t`Pridanie`,
            [METHODS.EDIT]: t`Úprava`,
            [METHODS.REMOVE]: t`Odstránanie`,
            [METHODS.FROM_CART]: t`Vytvorenie z košíka`,
            [METHODS.FROM_QUOTATION]: t`Vytvorenie z ponuky`,
            [METHODS.FROM_REQUEST]: t`Vytvorenie z dopytu`,
            [METHODS.FROM_INCOMING]: t`Vytvorenie z prichádzajúcej`,
            [METHODS.ADD_ITEM]: t`Pridanie položky`,
            [METHODS.EDIT_ITEM]: t`Úprava položky`,
            [METHODS.REMOVE_ITEM]: t`Odstránenie položky`,
            [METHODS.MERGE_WITH]: t`Zlúčenie duplicity`,
            [METHODS.CANCEL]: t`Zrušeie položky`,
            [METHODS.ACK]: t`Potvrdenie`,
            [METHODS.SEND]: t`Odoslanie`,
            [METHODS.PREVIEW]: t`Ukázanie e-mailu`,
            [METHODS.COPY]: t`Vytvorenie kópie`,
            [METHODS.LOAD_TO_CART]: t`Pridanie do košíka`,
            [METHODS.SHARE]: t`Zdieľanie`,
            [METHODS.SAVE]: t`Uloženie`,
            [METHODS.SAVE_AS]: t`Uloženie ako`,
            [METHODS.ADD_GROUP]: t`Pridanie do skupiny`,
            [METHODS.EDIT_GROUP]: t`Úprava skupinuy`,
            [METHODS.REMOVE_GROUP]: t`Odstránenie skupinuy`,
            [METHODS.EDIT_RULE]: t`Úprava pravidla`,
            [METHODS.REMOVE_RULE]: t`Odstránenie pravidla`,
            [METHODS.ADD_OPTION]: t`Pridanie možnosti`,
            [METHODS.EDIT_OPTION]: t`Úprava možnosti`,
            [METHODS.REMOVE_OPTION]: t`Odstránenie možnosť`,
            [METHODS.ADD_OPTION_VALUE]: t`Pridanie hodnoty`,
            [METHODS.REMOVE_OPTION_VALUE]: t`Odstránenie hodnoty`,
            [METHODS.EDIT_BARCODE]: t`Úprava čiarového kódu`,
            [METHODS.REMOVE_BARCODE]: t`Odstránenie čiarového kódu`,
            [METHODS.EDIT_HTML]: t`Úprava popisu`,
            [METHODS.EDIT_QUANTITY_UNITS]: t`Úprava jednotky`,
            [METHODS.EDIT_PARAM]: t`Úprava parametra`,
            [METHODS.REMOVE_PARAM]: t`Odstránenie parametra`,
            [METHODS.EDIT_SPECIAL_PRICE]: t`Úprava špeciálnej ceny`,
            [METHODS.REMOVE_SPECIAL_PRICE]: t`Odstránenie špeciálnej ceny`,
            [METHODS.EDIT_CUSTOM_CODE]: t`Úprava vlastného označenia`,
            [METHODS.remove_custom_code]: t`Odstránenie vlastného označenia`,
            [METHODS.EDIT_COMPONENT]: t`Úprava komponenty`,
            [METHODS.REMOVE_COMPONENT]: t`Odstránenie komponenty`,
            [METHODS.ADD_REPLACEMENT]: t`Pridanie náhrady`,
            [METHODS.REMOVE_REPLACEMENT]: t`Odstránenie náhrady`,
            [METHODS.ADD_RELATIVE]: t`Pridanie podobného`,
            [METHODS.REMOVE_RELATIVE]: t`Odstránenie podobného`,
            [METHODS.LOGIN]: t`Prihlásenie`,
            [METHODS.CHANGE_PASSWORD]: t`Zmenenie hesla`,
            [METHODS.RESET_PASSWORD]: t`Reset hesla`,
            [METHODS.FORGOTTEN_PASSWORD]: t`Zabudnuté heslo`,
            [METHODS.ACTIVATE]: t`Aktualizovanie`,
            [METHODS.ADD_TYPE]: t`Pridanie typu`,
            [METHODS.EDIT_TYPE]: t`Úprava typu`,
            [METHODS.REMOVE_TYPE]: t`Odstránenie typu`,
            [METHODS.ADD_LINE]: t`Pridanie položky`,
            [METHODS.EDIT_LINE]: t`Úprava položky`,
            [METHODS.REMOVE_LINE]: t`Odstránenie položky`,
            [METHODS.ACK_RECEIVE_LINE]: t`Potvrdenie prijatia položky`,
            [METHODS.ACK_DISPATCH_LINE]: t`Potvrdenie odoslania položky`,
            [METHODS.ISSUE_INVOICES]: t`Vystaviť faktúry`,
            [METHODS.ADD_INTERNAL]: t`Pridanie interne`,
            [METHODS.REMOVE_INTERNAL]: t`Odstránenie interne`,
            [METHODS.INTERNAL_ADD_LINE]: t`Pridanie položky interne`,
            [METHODS.INTERNAL_REMOVE_LINE]: t`Odstránenie položky interne`,
            [METHODS.ADD_RESERVATION]: t`Vytvoriť/predĺžiť rezerváciu`,
            [METHODS.REMOVE_RESERVATION]: t`Odstrániť rezerváciu`,
        };
    }
    
    const localized = objectHistoryMethodLabel.dict[value];
    if(showOrig)
        return localized ? `${localized} (${value})` :  value;
    else
        return localized ? localized : value;
}

export function objectHistoryArgsLabel(value) {
    if(objectHistoryArgsLabel.dict == null)
    {
        objectHistoryArgsLabel.dict = {
            [ARGS.ID_CUSTOMER]: t`Zákazník`,
            [ARGS.ID_INVOICE_ADDRESS]: t`Fakturačná adresa`,
            [ARGS.ID_DELIVERY_ADDRESS]: t`Dodacia adresa`,
            [ARGS.ID_TRANSPORT_TYPE]: t`Spôsob prepravy`,
            [ARGS.ID_PAYMENT_METHOD]: t`Spôsob platby`,
            [ARGS.ID_USER]: t`Kontakt`,
            [ARGS.ID_ITEM]: t`Položka`,
            [ARGS.ID_MANUFACTURER]: t`Výrobca`,
            [ARGS.ID_PRODUCT_GROUP]: t`Produktová skupina`,
            [ARGS.ID_DISCOUNT_GROUP]: t`Rabatová skupina`,
            [ARGS.CUSTOMER_REFERENCE]: t`Označenie zákazníka`,
            [ARGS.IS_DIVISIBLE]: t`Deliteľnosť`,
            [ARGS.NOTICE]: t`Poznámka`,
            [ARGS.CUSTOMER_NOTICE]: t`Verejná poznámka (položka)`,
            [ARGS.DEALER_NOTICE]: t`Interná poznámka (položka)`,
            [ARGS.CUSTOMER_NOTICES]: t`Verejná poznámka`,
            [ARGS.DEALER_NOTICES]: t`Interná poznámka`,
            [ARGS.ID_ORDER]: t`Objednávka`,
            [ARGS.ID_QUOTATION]: t`Cenová ponuka`,
            [ARGS.ID_REQUEST]: t`Dopyt`,
            [ARGS.ID_CART_ITEM]: t`Položka v košíku`,
            [ARGS.ID_REQUEST_ITEM]: t`Položka dopytu`,
            [ARGS.ID_QUOTATION_ITEM]: t`Položka ponuky`,
            [ARGS.ID_ORDER_ITEM]: t`Položka objednávky`,
            [ARGS.ID_TRANSFER_ITEM]: t`Položka dokladu`,
            [ARGS.ID_INVOICE_ITEM]: t`Položka faktúry`,
            [ARGS.ID_STOCK_ITEM]: t`Položka skladu`,
            [ARGS.ID_PRODUCT]: t`Produkt`,
            [ARGS.ID_FORM]: t`Kustomizácia`,
            [ARGS.ID_WHAT]: t`Pôvodný produkt`,
            [ARGS.ID_WHERE]: t`Cieĺový produkt`,
            [ARGS.ID_STATUS]: t`Status`,
            [ARGS.QUANTITY]: t`Množstvo`,
            [ARGS.PACKAGE_QUANTITY]: t`Množstvo`,
            [ARGS.QUANTITY_DIV]: t`Nedeliteľné mnonžstvo`,
            [ARGS.QUANTITY_UNIT]: t`Množstevná jednotka`,
            [ARGS.QUANTITY_MIN]: t`Množstevné minimum`,
            [ARGS.QUANTITY_BULK]: t`Balík`,
            [ARGS.QUANTITY_PALLET]: t`Paleta`,
            [ARGS.UNIT_PRICE]: t`Cena za jednotku`,
            [ARGS.PACKAGE_PRICE]: t`Cena za jednotku`,
            [ARGS.ID_PACKAGE_TYPE]: t`Jednotka`,
            [ARGS.ID_FINAL_CUSTOMER]: t`Konečný zákazník`,
            [ARGS.ID_STOCK]: t`Sklad`,
            [ARGS.TAX_RATE]: t`Daň`,
            [ARGS.REQUESTED_AT]: t`Dodací termín`,
            [ARGS.AVAILABLE_AT]: t`Dodací termín`,
            [ARGS.TRADE]: t`Typ obchodu`,
            [ARGS.INVOICE_ADDRESS]: t`Fakturačná adresa`,
            [ARGS.DELIVERY_ADDRESS]: t`Dodacia adresa`,
            [ARGS.ITEMS]: t`Položky`,
            [ARGS.LAST_CHANGE]: t`Posledná zmena`,
            [ARGS.SEND_AT]: t`Odoslané`,
            [ARGS.QUOTATION_REFERENCE]: t`Ref. cenovej ponuky`,
            [ARGS.REQUEST_REFERENCE]: t`Ref. dopytu`,
            [ARGS.ACTIVE_TO]: t`Aktívne do`,
            [ARGS.DIVISIBILITY_LEVEL]: t`Úroveň deliteľnosti`,
            [ARGS.CUSTOMER_CODE]: t`Vlastné označenie`,
            [ARGS.SUPPLY_DURATION]: t`Trvanie zásobovania`,
            [ARGS.SEND]: t`Má sa odoslať`,
            [ARGS.SEQUENCE]: t`Poradie`,
            [ARGS.DESIGNATION]: t`Názov`,
            [ARGS.IS_VISIBLE]: t`Je viditeľné`,
            [ARGS.IS_SERVICE]: t`Je služba`,
            [ARGS.STOCK_RESERVE]: t`Skladová rezerva`,
            [ARGS.MASS]: t`Hmotnosť`,
            [ARGS.DESCRIPTION_SHORT]: t`Krátky popis`,
            [ARGS.DESCRIPTION_HTML]: t`Detailný popis`,
            [ARGS.LIST_PRICE]: t`Cena`,
            [ARGS.EXT_REF]: t`Ext. referencia`,
            [ARGS.THUMBNAIL]: t`Obrázok`,
            [ARGS.AUTO_SUPPLY]: t`Automatické zásobovanie`,
            [ARGS.PARAMETERS]: t`Parametre`,
            [ARGS.ID_PARAMETER]: t`Parameter`,
            [ARGS.COMPONENTS]: t`Komponenty`,
            [ARGS.BARCODES]: t`Čiarové kódy`,
            [ARGS.ID_RELATIVE]: t`Podobný produkt`,
            [ARGS.ID_REPLACEMENT]: t`Náhradný produkt`,
            [ARGS.MERGE_WITH]: t`Spojiť s`,
            [ARGS.CODE]: t`Kód`,
            [ARGS.SCALE]: t`Pomer`,
            [ARGS.PRICE]: t`Cena`,
            [ARGS.TRANSPORT_PRICE]: t`Cena prepravy`,
            [ARGS.NAME]: t`Názov`,
            [ARGS.VAL]: t`Hodnota`,
            [ARGS.UNIT]: t`Jednotka`,
            [ARGS.IMAGE]: t`Obrázok`,
            [ARGS.ARGS]: t`Argumenty`,
            [ARGS.LABEL]: t`Označenie`,
        };
    }
    const localized = objectHistoryArgsLabel.dict[value];
    return localized ? `${localized} (${value})` :  value;
}