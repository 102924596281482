import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import {GLOBAL_DATA} from '../../constants/globalData';
import generalFailedPC from '../fetch/generalFailedPC';
import Tooltip from '../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { Alert, UserInfo } from '../../constants/propTypesDefinitions';
import { createFetchAlertAck } from '../../backend/apiCalls';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { objectHistoryClassLabel, objectHistoryMethodLabel } from '../../lib/localizedHistoryLabels';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import withDataHOC from '../dataProvider/withDataHOC';
import ButtonFetch from '../fetch/ButtonFetch';

/**
 * @dusan
 */


 class AlertAck extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.RIGHTS]: PropTypes.number.isRequired,
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
        alert: Alert.isRequired,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {alert = {}, [SESSION_ATTRIBUTES.RIGHTS]: rights, [GLOBAL_DATA.ACCOUNT_INFO]: account, reload, ...props} = this.props;
        const disabled = alert.is_active == 0 || (
            alert.id_addressee != null ? 
                (alert.id_addressee != account.id) : 
                (alert.min_access_level != null ? (rights < alert.min_access_level) : false)
        );

        return alert.method == null ? 
        <ButtonFetch
            type="primary"
            icon="check"
            values={{alert}}
            size="small"
            disabled={disabled}
            Failed={generalFailedPC(t`Nepodarilo sa potvrdiť upozornenie.`)}
            fetchFunction={createFetchAlertAck(alert.id, null)}
            onFinish={reload}
        /> : 
        <FormModal
            {...props}
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Vyriešiť požiadavku</Trans>}>
                    <Button
                        disabled={disabled}
                        size="small"
                        type="primary"
                        icon="check"
                    />
                </Tooltip>
            }
            values={{alert}}
            onFinishSuccessful={reload}
            title={<Trans>Požiadavka</Trans>}
            Form={AlertExecForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vyriešiť požiadavku.`)}
        />;
    }   

}


class AlertExecForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            alert: Alert.isRequired,
        }).isRequired,
    };

    onAckButtonClick = () => {
        const {onSubmitFinish, values} = this.props;
        const {alert} = values;
        createFetchAlertAck(alert.id, 1)().then((response) => {
            onSubmitFinish(response);
        });
    }

    onNackButtonClick = () => {
        const {onSubmitFinish, values} = this.props;
        const {alert} = values;
        createFetchAlertAck(alert.id, 0)().then((response) => {
            onSubmitFinish(response);
        });
    }

    render() {
        const {onClose, values} = this.props;
        const {alert = {}} = values;
        const label = t`Naozaj chcete vykonať požadovanú akciu` + ' ' + objectHistoryClassLabel(alert.class, false) + ': ' + objectHistoryMethodLabel(alert.method, false) + '?';
        return <div>
            <h5>{alert.message}</h5>
            <p>{label}</p>
            <div className="mt-3 d-flex justify-content-end">
                <Button onClick={onClose}>
                    <Trans>Späť</Trans>
                </Button>
                <Button onClick={this.onAckButtonClick} type="primary">
                    <Trans>Vykonať</Trans>
                </Button>
                <Button onClick={this.onNackButtonClick} type="danger">
                    <Trans>Zamietnuť</Trans>
                </Button>
            </div>
        </div>;
    }
}


export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(
    withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(AlertAck)
);