import React from 'react';
import locationHOC from '../locationProvider/locationHOC';
import CustomersSelect from '../project/CustomersSelect';
import ColumnFilterLayout from '../project/ColumnFilterLayout';
import DateRangePicker from '../general/DateRangePicker';
import InputText from '../general/InputText';
import {getQueryParam, getQueryParamNumber,  createSetQueryParamValue, navigateToParametrized} from '../../lib/url';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Location} from '../../constants/propTypesDefinitions';
import {Button, Checkbox} from 'antd';
import {Trans, t} from '@lingui/macro';
import AlertTypesSelect from '../project/AlertTypesSelect';
import EmployeesSelect from '../project/EmployeesSelect';
import ClassSelect from '../project/ClassSelect';
import { getInputOnChangeEventChecked } from '../../lib/project';
import { RIGHTS } from '../../constants/Rights';
import RightsWrapper from '../sessionProvider/RightsWrapper';

/**
 * @dusan
 */

class AlertParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ALERT_PHRASE]: undefined,
            [QUERY_PARAMS.ALERT_ID_TYPE]: undefined,
            [QUERY_PARAMS.ALERT_CLASS]: undefined,
            [QUERY_PARAMS.ALERT_ID_CUSTOMER]: undefined,
            [QUERY_PARAMS.ALERT_ID_SALESMAN]: undefined,
            [QUERY_PARAMS.ALERT_ID_SALESMAN2]: undefined,
            [QUERY_PARAMS.ALERT_TIM]: undefined,
            [QUERY_PARAMS.ALERT_SHOW_ALL]: undefined,
            [QUERY_PARAMS.ALERT_SHOW_FOREIGN]: undefined,
            [QUERY_PARAMS.ALERT_ORDER_BY]: undefined,
            [QUERY_PARAMS.ALERT_SCROLL]: undefined,
            [QUERY_PARAMS.ALERT_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
        });
    };

    render() {
        const {location} = this.props;
        const typeId = getQueryParam(location, QUERY_PARAMS.ALERT_ID_TYPE);
        const salesmanId = getQueryParamNumber(location, QUERY_PARAMS.ALERT_ID_SALESMAN);
        const salesman2Id = getQueryParamNumber(location, QUERY_PARAMS.ALERT_ID_SALESMAN2);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ALERT_ID_CUSTOMER);
        const className = getQueryParam(location, QUERY_PARAMS.ALERT_CLASS);
        const tim = getQueryParam(location, QUERY_PARAMS.ALERT_TIM);
        const showAll = getQueryParamNumber(location, QUERY_PARAMS.ALERT_SHOW_ALL);
        const showForeign = getQueryParamNumber(location, QUERY_PARAMS.ALERT_SHOW_FOREIGN);
        const phrase = getQueryParam(location, QUERY_PARAMS.ALERT_PHRASE);
       
        const hasFilter = typeId != null || salesmanId != null || salesman2Id != null || showAll != null || showForeign != null || className != null || 
        tim != null || phrase != null;
        return <ColumnFilterLayout
            className="p-1"
            filters={[
                [
                    {
                        label: <Trans>Vyhľadávať: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_PHRASE)}
                            value={phrase}
                        />
                    },
                    {
                        label: <Trans>Typ upozornenia: </Trans>,
                        selector: <AlertTypesSelect
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_ID_TYPE)}
                            value={typeId}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Trieda: </Trans>,
                        selector: <ClassSelect
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_CLASS)}
                            value={className}
                            allowClear={true}
                        />
                    }
                ],
                [
                    {
                        label: <Trans>Organizácia: </Trans>,
                        selector: <CustomersSelect
                            className="full-size-width"
                            value={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_ID_CUSTOMER)}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Zodpovedný obchodník: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesmanId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_ID_SALESMAN)}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Logista: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={salesman2Id}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_ID_SALESMAN2)}
                            allowClear={true}
                        />
                    },                    
                ],
                [
                    {
                        label: <Trans>Dátum: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ALERT_TIM)}
                            value={tim}
                        />,
                    },
                    {
                        selector: <div className="d-flex justify-content-end align-items-center">
                            <RightsWrapper from={RIGHTS.SUPERVISOR}>
                                <Checkbox
                                    checked={showForeign != null && showForeign > 0}
                                    onChange={(ev) => {
                                        const value = getInputOnChangeEventChecked(ev) ? 1 : undefined;
                                        navigateToParametrized(location, null, {[QUERY_PARAMS.ALERT_SHOW_FOREIGN]: value});
                                    }}
                                >
                                    <Trans>všetci pracovníci</Trans>
                                </Checkbox>
                            </RightsWrapper>
                            <Checkbox
                                checked={showAll != null && showAll > 0}
                                onChange={(ev) => {
                                    const value = getInputOnChangeEventChecked(ev) ? 1 : undefined;
                                    navigateToParametrized(location, null, {[QUERY_PARAMS.ALERT_SHOW_ALL]: value});
                                }}
                            >
                                <Trans>aj neaktívne</Trans>
                            </Checkbox>
                        </div>
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ]
            ]}
        />;
    }

}

export default locationHOC(AlertParams);